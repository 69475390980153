app.controller('summary', function ($scope, authService, $location, $http, appConfig, $routeParams, ngDialog, modalService, messageBanner) {
  if (!authService.isAuthenticated()) {
    authService.redirectToLogin()
  }

  $scope.domain = $routeParams.domain
  $scope.duration = $routeParams.duration || 300
  $scope.split = $routeParams.split

  var getSummaryData = function () {
    $scope.endpointSummaries = null
    $scope.groupedSummaries = null

    $scope.loading = true

    var url = appConfig.API_URL + '/api/v1/summary'
    if ($scope.domain) {
      url = url + '/' + $scope.domain
    }

    $http.get(url, {
      params: {
        duration: $scope.duration
      }
    })
    .then(function (response) {
      $scope.loading = false
      $scope.endpointSummaries = _.map(response.data.data, function (summary) {
        summary.uri = summary.normalized_url.split('/').splice(3)
        return summary
      })
      $scope.groupedSummaries = _.groupBy($scope.endpointSummaries, function (s) {
        var url = s.base_url
        for (var i = 0; i < $scope.split; i++) {
          url = url + '/' + s.uri[i]
        }
        return url
      })
    })
  }

  var getHealthCheckData = function () {
    $http.get(appConfig.API_URL + '/api/v1/ping')
      .then(function (response) {
        if (response.data.data && response.data.data.length) {
          authService.logEvent('added_ping')
        }

        $scope.healthByDomain = _.keyBy(response.data.data, function (p) {
          var a = document.createElement('a')
          a.href = p.request_url
          return a.hostname
        })
      })
  }

  var getDomains = function () {
    $http.get(appConfig.API_URL + '/api/v1/domains')
      .then(function (response) {
        $scope.domains = response.data.data

        if ($scope.domains.length) {
          authService.logEvent('seen_data')
        }
      })
  }

  $scope.changeDuration = function (duration) {
    $scope.duration = duration
    $location.search('duration', duration)

    getSummaryData()
  }

  $scope.changeDomain = function (domain) {
    $scope.domain = domain
    $location.search('domain', domain)

    getSummaryData()
  }

  getSummaryData()
  getHealthCheckData()
  getDomains()

  $scope.setupHealthCheck = function (domain) {
    var dialog = ngDialog.open({
      template: 'healthCheckModal.html',
      controller: 'healthCheckModal',
      data: {
        domain: domain,
        baseUrlHash: _.first($scope.groupedSummaries[domain]).base_url_hash
      }
    })

    dialog.closePromise.then(function (returnObj) {
      var data = modalService.getModalData(returnObj)
      if (data) {
        $http.post(appConfig.API_URL + '/api/v1/ping', {
          endpoint_id: data.endpoint_id,
          request_method: data.request_method,
          request_url: data.normalized_url,
          base_url: data.base_url,
          base_url_hash: data.base_url_hash
        })
        .then(function (response) {
          messageBanner.display('info', 'Health check created, status will display momentarily')
          getHealthCheckData()
        }, function (err) {
          messageBanner.display('error', 'Whoops! An error occured when adding the health check.')
        })
      }
    })
  }

  $scope.removeHealthCheck = function (check) {
    $http.delete(appConfig.API_URL + '/api/v1/ping/' + check.endpoint_id)
      .then(function () {
        messageBanner.display('info', 'Health check removed successfully')
        getHealthCheckData()
      }, function () {
        messageBanner.display('error', 'Whoops! An error occured when removing the health check.')
      })
  }
})
