app.directive('plot', function (authService, $location, stylesheetService, $window) {
  return {
    scope: {
      x: '=',
      y: '='
    },
    replace: true,
    templateUrl: 'plot.html',
    link: function ($scope, element, attrs) {
      $scope.attrs = attrs

      var plot
      var makePlot = function () {
        if (plot) {
          plot.destroy()
        }

        // obtian a color
        attrs.color = attrs.color || 'blue'
        var color = stylesheetService.getStylesForClass(attrs.color)
        if (color) {
          color = color.match(/color: (.*);/, 'ig')[1]
        }

        // modify the data in a structure that Plottable
        // can actually plot with
        var data = _.zipObject($scope.x, $scope.y)
        data = _.map(data, function (value, key) {
          return {
            x: key,
            y: value
          }
        })

        // chose type of plot
        if (attrs.type === 'area') {
          plot = new Plottable.Plots.Area()
        } else if (attrs.type === 'bar') {
          plot = new Plottable.Plots.Bar()
        } else if (attrs.type === 'line') {
          plot = new Plottable.Plots.Line()
        }

        // actually make the plot
        plot
          .addDataset(new Plottable.Dataset(data))
          .x(function (d) {
            return d.x
          }, new Plottable.Scales.Category())
          .y(function (d) {
            return d.y
          }, new Plottable.Scales.Linear())
          .attr("stroke", color)
          .attr("fill", color)
          .attr("stroke-width", attrs.strokeWidth || 1)
          .renderTo(angular.element(element).find('svg')[0])
      }

      $scope.$watch('x', makePlot)
      $scope.$watch('y', makePlot)
      makePlot()

      $window.addEventListener("resize", _.debounce(function () {
        plot.redraw()
      }, 1000))
    }
  }
})
