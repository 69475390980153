app.controller('slackIntegration', function ($scope, appConfig, $routeParams, $http, $location, messageBanner, authService) {
  if ($routeParams.code) {
    $http.get(appConfig.API_URL + '/auth/v1/slack/callback', {
      params: {
        code: $routeParams.code,
        state: $routeParams.state
      }
    })
    .then(function (response) {
      authService.logEvent('added_slack')
      return
    }, function (response) {
      messageBanner.display('error', 'There was an issue connecting with Slack, please try again.')
      return
    })
    .then(function () {
      $location.path('/team/notifications')
      $location.search({})
    })
  } else {
    location.href = appConfig.API_URL + '/auth/v1/slack'
  }
})
