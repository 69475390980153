app.directive('summaryRow', function (authService, $location) {
  return {
    scope: {
      summary: '='
    },
    replace: true,
    templateUrl: 'summaryRow.html',
    link: function ($scope, element) {
      $scope.formatUrl = function (url) {
        var a = document.createElement('a')
        a.href = url

        var html = a.pathname
        html = decodeURIComponent(html)
        html = html.replace(/(:[^\/]*)/g, '<span>$1</span>')
        html = html.replace(/({.*})/g, '<span>$1</span>')
        html = html.replace(/\/(\*)/, '/<span>$1</span>')
        return html
      }

      $scope.alertsString = function (alerts) {
        var alertTitles = _.map(alerts, function (a) {
          return a.title
        })

        return alertTitles.join(', ')
      }

      $scope.requestCount = _.sum($scope.summary.total_request_counts)
      $scope.requestTime = _.chain($scope.summary.percentile_90_times_in_millis)
        .filter(function (v) {
          return v > 0
        })
        .last()
        .value()
      $scope.errorCount = _.sum($scope.summary.response_status_5xx_counts)
    }
  }
})
