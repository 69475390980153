app.controller('login', function ($scope, authService, $routeParams, $location) {
  $scope.email = null
  $scope.password = null

  if (authService.isAuthenticated()) {
    $location.path()
    authService.goNext($routeParams.next)
  }

  $scope.submit = function () {
    authService.login($scope.email, $scope.password)
      .then(function (user) {
        $scope.password = null

        if (user) {
          authService.goNext($routeParams.next)
        }
      })
  }
})
