app.controller('inviteTeammate', function ($scope) {
  $scope.fullName = ''
  $scope.email = ''
  $scope.role = 'member'

  $scope.submit = function () {
    var data = {
      fullName: $scope.fullName,
      email: $scope.email,
      isAdmin: $scope.role === 'admin'
    }

    $scope.closeThisDialog(data)
  }
})
