app.service('modalService', function (ngDialog) {
  var getModalData = this.getModalData = function (data) {
    if (typeof data.value === 'string' && data.value.indexOf('$') === 0) {
      return null
    }

    return data.value
  }

  this.confirm = function (type, message) {
    var dialog = ngDialog.open({
      template: 'confirmModal.html',
      controller: 'confirmModal',
      data: {
        type: type,
        message: message
      }
    })

    return dialog.closePromise.then(function (returnObj) {
      if (getModalData(returnObj)) {
        return true
      }
    })
  }
})
