app.controller('teamInfo', function ($scope, authService, appConfig, $http, messageBanner) {
  if (!authService.isAuthenticated()) {
    authService.redirectToLogin()
  }

  var interpretResponse = function (response) {
    $scope.team = response.data.data
    $scope.whitelist = response.data.data.web_domains_whitelist
    $scope.apiKey = response.data.data.api_key
  }

  var getTeam = function () {
    $http.get(appConfig.API_URL + '/api/v1/teams/' + authService.currentUser().team.id)
      .then(interpretResponse)
  }

  getTeam()
})
