app.controller('teammates', function ($scope, appConfig, $http, ngDialog, messageBanner, modalService, authService) {
  if (!authService.isAuthenticated()) {
    authService.redirectToLogin()
  }

  $scope.currentUser = authService.currentUser()

  var getUsers = function () {
    $http.get(appConfig.API_URL + '/api/v1/users')
      .then(function (resp) {
        $scope.users = resp.data.data
      })
  }

  var sendInvite = function (email, fullName, isAdmin) {
    $http.post(appConfig.API_URL + '/api/v1/users', {
      user: {
        email: email,
        full_name: fullName,
        is_admin: isAdmin || false
      }
    })
    .then(function () {
      messageBanner.display('info', 'Invite sent to ' + email)
      getUsers()
    })
  }

  $scope.invite = function () {
    var dialog = ngDialog.open({
      template: 'inviteTeammate.html',
      controller: 'inviteTeammate'
    })

    dialog.closePromise.then(function (returnObj) {
      var data = modalService.getModalData(returnObj)
      if (data) {
        sendInvite(data.email, data.fullName, data.isAdmin)
      }
    })
  }

  var deleteUser = function (user) {
    $http.delete(appConfig.API_URL + '/api/v1/users/' + user.id)
      .then(function (resp) {
        messageBanner.display('info', 'Removed ' + user.full_name || user.email + ' from your team')
        getUsers()
      })
  }

  $scope.removeUser = function (user) {
    var message = 'Are you sure you want to remove *';
    if (user.full_name && user.full_name.length) {
      message = message + user.full_name
    } else {
      message = message + user.email
    }
    message = message + '* from your team?'

    modalService.confirm('danger', message).then(function (confirmed) {
      if (confirmed) {
        deleteUser(user)
      }
    })
  }

  getUsers()
})
