app.controller('accountNotifications', function ($scope, authService, appConfig, $http) {
  $scope.user = _.clone(authService.currentUser())

  $scope.set = function (key, value) {
    var data = {}
    data[key] = value

    $http.put(appConfig.API_URL + '/api/v1/users/' + $scope.user.id, {
      "user": data
    })
    .then(function (resp) {
      $scope.user[key] = resp.data.data[key]
      authService.updateUser()
    })
  }
})
