app.config(function ($routeProvider, $locationProvider, $httpProvider, $provide, cfpLoadingBarProvider) {
  $locationProvider.html5Mode({
    enabled: true,
    requireBase: false
  })

  var routes = {
    '/': {
      controller: 'home'
    },
    '/summary': {
      controller: 'summary',
      reloadOnSearch: false
    },
    // '/register': {
    //   controller: 'register'
    // },
    '/register/:inviteToken': {
      controller: 'register'
    },
    '/login': {
      controller: 'login'
    },
    '/logout': {
      controller: 'logout'
    },
    '/verify': {
      controller: 'verify'
    },
    '/verify/:token': {
      controller: 'verify'
    },
    '/reset_password': {
      controller: 'resetPassword'
    },
    '/reset_password/:token': {
      controller: 'resetPassword'
    },
    '/setup': {
      controller: 'setup'
    },
    '/team': {
      redirectTo: '/team/info'
    },
    '/team/info': {
      controller: 'teamInfo'
    },
    '/team/members': {
      controller: 'teammates'
    },
    '/team/notifications': {
      controller: 'teamNotifications'
    },
    '/team/billing': {
      controller: 'teamBilling'
    },
    '/slack': {
      controller: 'slackIntegration'
    },
    '/account': {
      controller: 'manageAccount'
    },
    '/account/notifications': {
      controller: 'accountNotifications'
    },
    '/admin': {
      redirectTo: '/admin/teams'
    },
    '/admin/teams': {
      controller: 'listTeams'
    },
    '/admin/notifications': {
      controller: 'listNotifications'
    },
    '/admin/shadow/:team_id': {
      controller: 'shadowTeam'
    },
    '/alerts': {
      controller: 'listAlerts'
    }
  }

  for (var route in routes) {
    var controller = routes[route].controller

    var reloadOnSearch = routes[route].reloadOnSearch
    if (reloadOnSearch === undefined) {
      reloadOnSearch = true
    }

    if (routes[route].redirectTo) {
      $routeProvider.when(route, {
        redirectTo: routes[route].redirectTo
      })
      continue
    }

    $routeProvider.when(route, {
      templateUrl: controller + '.html',
      controller: controller,
      reloadOnSearch: reloadOnSearch,
      resolve: routes[route].resolve || {}
    })
  }

  $routeProvider.otherwise({
    redirectTo: '/summary'
  })

  $provide.factory('httpInterceptor', function ($q, $rootScope, $cookies, $window) {
    return {
      // This helps display an error modal on backend errors
      response: function (response) {
        // do something on success
        return response || $q.when(response)
      },
      responseError: function (rejection) {
        // 401 session expired or token is invalid
        if (rejection.status === 401) {
          $rootScope.$emit('session_expired')
        }

        if (rejection.data) {
          if (!rejection.data.errors) {
            rejection.data.errors = []
          }

          if (typeof rejection.data.errors === "object" && !Array.isArray(rejection.data.errors)) {
            rejection.data.errors = _.keys(rejection.data.errors)
          }
        }

        // 500 is usually an unhandled error that we should
        // just display a generic error message for
        if (rejection.status >= 500) {
          $rootScope.$emit('error', rejection.data.errors)
        }

        return $q.reject(rejection)
      }
    }
  })
  $httpProvider.interceptors.push('httpInterceptor')

  // this line turns off the circle loading indicator and depends on
  // just the bar instead
  cfpLoadingBarProvider.includeSpinner = false
  cfpLoadingBarProvider.latencyThreshold = 1000
})

app.run(function ($rootScope, authService, messageBanner, $location) {
  // check for current user and embed it into our app
  authService.init()

  // session expired handling
  $rootScope.$on('session_expired', function (e) {
    authService.reset()
    authService.redirectToLogin()
  })

  // global error handling
  $rootScope.$on('error', function (e, data) {
    var message = 'An unexpected error has occurred.'

    if (data && data.errors) {
      message = message + ' ' + data.errors.join(', ')
    }

    messageBanner.display('error', message)
  })

  // log page views
  $rootScope.$on('$routeChangeSuccess', authService.logPageView)
})
