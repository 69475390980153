app.controller('register', function ($scope, authService, $location, $routeParams, appConfig, $http) {
  $scope.teamName = null
  $scope.fullName = null
  $scope.email = null
  $scope.password = null

  $scope.inviteToken = $routeParams.inviteToken
  $scope.precheck = false
  $scope.invalid = false

  if (authService.isAuthenticated()) {
    $location.path()
    authService.goNext('/setup')
  }

  if ($scope.inviteToken) {
    $http.put(appConfig.API_URL + '/public/v1/verify/invite', {
      token: $scope.inviteToken
    })
    .then(function (resp) {
      $scope.precheck = true
      $scope.fullName = resp.data.data.full_name
      $scope.email = resp.data.data.email
      $scope.teamName = resp.data.data.team.name
    }, function () {
      $scope.precheck = true
      $scope.invalid = true
    })
  } else {
    $scope.precheck = true
  }

  $scope.submit = function () {
    authService.register($scope.teamName, $scope.fullName, $scope.email, $scope.password, $scope.inviteToken)
      .then(function (user) {
        if (user) {
          if ($scope.inviteToken) {
            authService.goNext('/summary')
          } else {
            authService.goNext('/setup')
          }
        }
      })
  }
})
