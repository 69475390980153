app.controller('manageAccount', function ($scope, authService, appConfig, $http, messageBanner) {
  if (!authService.isAuthenticated()) {
    authService.redirectToLogin()
  }

  $scope.user = _.clone(authService.currentUser())

  $scope.updateUser = function () {
    $http.put(appConfig.API_URL + '/api/v1/users/' + $scope.user.id, {
      user: _.pick($scope.user, [
        'full_name'
      ])
    })
    .then(function (response) {
      messageBanner.display('info', 'Your info has been saved')
      authService.updateUser()
    })
  }

  $scope.currentPassword = null
  $scope.newPassword = null
  $scope.confirmNewPassword = null

  $scope.changePassword = function () {
    if ($scope.newPassword !== $scope.confirmNewPassword) {
      messageBanner.display('error', 'The new password fields do not match')
      return
    }

    $http.put(appConfig.API_URL + '/api/v1/change_password', {
      current_password: $scope.currentPassword,
      new_password: $scope.newPassword
    })
    .then(function (response) {
      $scope.currentPassword = null
      $scope.newPassword = null
      messageBanner.display('info', 'Password changed')
    }, function (response) {
      $scope.currentPassword = null
      $scope.newPassword = null

      if (_.includes(response.data.errors, 'invalid')) {
        messageBanner.display('error', 'Your current password is incorrect')
        return
      }

      messageBanner.display('error', 'An error has occured')
    })
  }
})
