app.controller('listAlerts', function ($scope, $http, appConfig, $anchorScroll) {
  var max = 0

  var getNotifications = function () {
    $http
      .get(appConfig.API_URL + '/api/v1/notifications', {
        params: {
          duration: 36000 // we should change this @john!
        }
      })
      .then(function (resp) {
        // modify notifications response
        $scope.notifications = _.chain(resp.data.data)
          .filter(function (notification) {
            return notification.alert
          })
          .map(function (notification) {
            notification.alert.notification = _.pick(notification, [
              'created_at'
            ])
            notification.alert.endpoint = notification.endpoint
            return notification
          })
          .reverse()
          .groupBy(function (notification) {
            return moment(notification.created_at + '-00').format('YYYYMMDD')
          })
          .value()

        // get the counts
        var counts = _.map($scope.notifications, function (arr) {
          return arr.length
        })
        max = _.max(counts)

        // get a readable format
        $scope.groupedNotifications = _.map($scope.notifications, function (notifications, date) {
          return {
            notifications: notifications,
            date: date
          }
        })
      })
  }

  // dates to show in the alert box
  var datePtr = moment().subtract(60, 'days')
  $scope.dates = []
  while ($scope.dates.length < 60) {
    datePtr = datePtr.add(1, 'day')
    $scope.dates.push(datePtr.format('YYYYMMDD'))
  }

  // helper functions
  $scope.normalizeHeight = function (count) {
    count = count || 0
    return Math.round((count / max) * 100)
  }

  $scope.scrollTo = function (date) {
    $anchorScroll('date-' + date)
  }

  getNotifications()
})
