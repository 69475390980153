app.directive('navbar', function (authService, $location) {
  return {
    replace: true,
    templateUrl: function (element, attrs) {
      return attrs.template
    },
    link: function ($scope, element) {
      $scope.currentUser = authService.currentUser()
      $scope.path = $location.path()
    }
  }
})
