app.filter('urlPath', function () {
  return function (url) {
    var a = document.createElement('a')
    a.href = url
    return a.pathname
  }
})

app.filter('urlHostname', function () {
  return function (url) {
    var a = document.createElement('a')
    a.href = url
    return a.hostname
  }
})

app.filter('formatTime', function () {
  return function (timestamp, format, parse) {
    if (!timestamp) {
      return
    }

    if (parse) {
      return moment(timestamp, parse).format(format)
    }

    if (isNaN(timestamp)) {
      return moment(timestamp + '-00').format(format)
    }

    return moment(timestamp, 'X').format(format)
  }
})

app.filter('calendarTime', function () {
  return function (timestamp) {
    if (!timestamp) {
      return
    }

    return moment(timestamp + '-00').calendar(null, {
      sameDay: '[Today at] h:mma',
      lastDay: '[Yesterday at] h:mma',
      lastWeek: '[Last] dddd [at] h:mma',
      nextDay: '',
      nextWeek: '',
      sameElse: 'MMM DD h:mma'
    })
  }
})

app.filter('abbreviateNumber', function () {
  return function (count) {
    if (count >= 1000000) {
      return (count / 1000000).toFixed(1) + 'M'
    }

    if (count >= 1000) {
      return (count / 1000).toFixed(1) + 'k'
    }

    return count
  }
})

app.filter('pluralize', function () {
  return function (count, single, plural) {
    if (count === 0) {
      return plural
    }
    if (count > 1) {
      return plural
    }
    return single
  }
})

app.filter('capitalize', function() {
  return function (input) {
    return (!!input) ? input.charAt(0).toUpperCase() + input.substr(1).toLowerCase() : '';
  }
})
