app.controller('setup', function ($scope, authService, appConfig, $http, messageBanner) {
  var interpretResponse = function (response) {
    $scope.whitelist = response.data.data.web_domains_whitelist
    $scope.apiKey = response.data.data.api_key
  }

  $scope.getRepoReadme = function (repo) {
    $http.get('https://api.github.com/repos/perflabs/' + repo + '/readme', {
      headers: {
        'Authorization': undefined,
        'Accept': 'application/vnd.github.v3.html'
      }
    })
    .then(function (resp) {
      $scope.readme = resp.data
      $scope.readme = $scope.readme.replace('PERF_API_KEY', $scope.apiKey)
    })
  }

  var getTeam = function () {
    $http.get(appConfig.API_URL + '/api/v1/teams/' + authService.currentUser().team.id)
      .then(interpretResponse)
  }

  getTeam()
})
