app.controller('listTeams', function ($scope, appConfig, $http, authService, $location) {
  if (!authService.isAuthenticated()) {
    authService.redirectToLogin()
  }

  if (!authService.isSuperAdmin()) {
    $location.path('/')
  }

  var getUsers = function () {
    $http.get(appConfig.API_URL + '/api/v1/admin/users')
      .then(function (response) {
        var groupedUsers = _.groupBy(response.data.data, function (user) {
          return user.team_id
        })

        for (var teamId in groupedUsers) {
          if ($scope.teams[teamId]) {
            $scope.teams[teamId].users = groupedUsers[teamId]
          }
        }
      })
  }

  var getTeams = function () {
    $http.get(appConfig.API_URL + '/api/v1/admin/teams')
      .then(function (response) {
        $scope.teams = _.keyBy(response.data.data, "id")
        getUsers()
      })
  }

  getTeams()
})
