app.controller('listNotifications', function ($scope, appConfig, $http, authService, $location) {
  if (!authService.isAuthenticated()) {
    authService.redirectToLogin()
  }

  if (!authService.isSuperAdmin()) {
    $location.path('/')
  }

  $http.get(appConfig.API_URL + '/api/v1/admin/notifications')
    .then(function (resp) {
      $scope.notifications = _.filter(resp.data.data, function (r) {
        return r.alert
      })
    })

  $scope.clearAlert = function (alert) {
    $http.post(appConfig.API_URL + '/api/v1/alerts/' + alert.id + '/clear').then(function (resp) {
      alert.cleared_at = resp.data.data.cleared_at
    })
  }
})
