app.controller('verify', function ($scope, $http, $location, authService, $routeParams, appConfig) {
  $scope.currentUser = authService.currentUser()

  $scope.states = {
    NEUTRAL: 0,
    VERIFIED: 1,
    ERROR: -1,
    REQUESTED: -2
  }

  $scope.state = $scope.states.NEUTRAL

  var verifyToken = function (token) {
    $http.put(appConfig.API_URL + '/public/v1/verify/email', {
      token: token
    })
    .then(function (response) {
      if (response.data && response.data.success) {
        $scope.state = $scope.states.VERIFIED
      } else {
        $scope.state = $scope.states.ERROR
      }
    }, function () {
      $scope.state = $scope.states.ERROR
    })
  }

  $scope.requestNewLink = function () {
    if (!authService.isAuthenticated()) {
      authService.redirectToLogin()
      return
    }

    $http
      .put(appConfig.API_URL + '/api/v1/verify/email/resend')
      .success(function (response) {
        $scope.state = $scope.states.REQUESTED
      })
  }

  if ($routeParams.token) {
    verifyToken($routeParams.token)
  } else {
    $scope.requestNewLink()
  }
})
