app.controller('confirmModal', function ($scope) {
  $scope.type = $scope.ngDialogData.type
  $scope.message = $scope.ngDialogData.message.replace(/\*(.*)\*/, '<strong>$1</strong>')

  $scope.confirm = function () {
    $scope.closeThisDialog(true)
  }

  $scope.close = function () {
    $scope.closeThisDialog()
  }
})
