app.controller('teamBilling', function ($scope, $http, appConfig, authService) {
  var getTeamInfo = function () {
    var currentUser = authService.currentUser()
    $http.get(appConfig.API_URL + '/api/v1/teams/' + currentUser.team.id)
      .then(function (resp) {
        $scope.team = resp.data.data
      })
  }

  getTeamInfo()

  $scope.months = _.range(1, 13)
  $scope.years = _.map(_.range(0,20), function(year) {
    return moment().add(year, 'years').format('YYYY')
  })

  $scope.cardNumber = ''
  $scope.cardMonth = ''
  $scope.cardYear = ''
  $scope.cardZipcode = ''
  $scope.cardCvc = ''

  $scope.errors = null

  var submitToken = function(stripeTokenId) {
    $http.post(appConfig.API_URL + '/api/v1/stripes', {
      token: stripeTokenId
    })
    .then(function (resp) {

    })
  }

  var stripeHandler = function(status, response) {
    if (response.error) {
      $scope.errors = response.error.message
      return
    }

    // send token
    submitToken(response.id)
  }

  $scope.subscribe = function() {
    $scope.errors = null

    if (!Stripe.card.validateCardNumber($scope.cardNumber)) {
      $scope.errors = "That card number doesn't seem valid"
      return
    }

    if (!Stripe.card.validateExpiry($scope.cardMonth, $scope.cardYear)) {
      $scope.errors = "The expiration date doesn't seem valid"
      return
    }

    if (!Stripe.card.validateCVC($scope.cardCvc)) {
      $scope.errors = "CVC code is invalid"
      return
    }

    Stripe.card.createToken({
      number: $scope.cardNumber,
      cvc: $scope.cardCvc,
      exp_month: $scope.cardMonth,
      exp_year: $scope.cardYear,
      address_zip: $scope.address_zip
    }, stripeHandler)
  }
})
