app.controller('resetPassword', function ($scope, $http, appConfig, $location, authService, $routeParams, messageBanner) {
  $scope.states = {
    NEUTRAL: 0,
    REQUEST: 1,
    REQUESTED: 2,
    ERROR: 3,
    NEW_PASSWORD: 4
  }
  $scope.state = $scope.states.NEUTRAL

  if (authService.isAuthenticated()) {
    $location.path('/')
  }

  //
  // For when requesting a reset password email
  //
  $scope.email = null
  $scope.requestReset = function () {
    $http.put(appConfig.API_URL + '/auth/v1/reset_password', {
      email: $scope.email
    })
    .then(function (response) {
      $scope.state = $scope.states.REQUESTED
    })
  }

  //
  // Check to see which state we need to accept
  //
  if ($routeParams.token) {
    $http.put(appConfig.API_URL + '/auth/v1/reset_password/verify', {
      token: $routeParams.token
    })
    .then(function (response) {
      if (response.data && response.data.errors || response.status !== 200) {
        $scope.state = $scope.states.ERROR
      } else {
        $scope.state = $scope.states.NEW_PASSWORD
      }
    })
  } else {
    $scope.state = $scope.states.REQUEST
  }

  //
  // To go back when ERROR'ed out
  //
  $scope.requestNew = function () {
    $location.path('/reset_password')
    $scope.state = $scope.states.REQUEST
  }

  //
  // When we've gotten to this state we
  // should verify and confirm password
  //
  $scope.password = null
  $scope.confirm_password = null
  $scope.resetPassword = function () {
    if ($scope.password !== $scope.confirm_password) {
      messageBanner.display('error', 'Passwords do not seem to match')
      return
    }

    $http.put(appConfig.API_URL + '/auth/v1/reset_password/confirm', {
      token: $routeParams.token,
      password: $scope.password
    })
    .then(authService.handleAuthentication, function (response) {
      if (_.includes(response.data.errors, "password")) {
        messageBanner.display('error', 'Your password needs to be at least 6 characters long')
        return
      }

      messageBanner.display('error', 'An error occurred when resetting. ' + response.data.errors.join(', '))
    })
    .then(function (user) {
      if (user) {
        $location.path('/')
      }
    })
  }
})
