app.directive('menubar', function (authService, $location) {
  return {
    replace: true,
    templateUrl: 'menubar.html',
    link: function ($scope, element) {
      $scope.currentUser = authService.currentUser()
      $scope.isShadowing = false

      $scope.unshadow = function () {
        authService.unshadow()
      }

      $scope.$watch(function () {
        return authService.isShadowing()
      }, function (newVal) {
        $scope.isShadowing = newVal
      })
    }
  }
})
