app.service('messageBanner', function ($rootElement, $timeout) {
  var display = this.display = function (type, message, timeout) {
    // out div
    var div = document.createElement('div')
    div.className = 'messageBanner animated fadeIn ' + type

    // inner div that pads everything
    var innerDiv = document.createElement('div')
    innerDiv.className = 'inner container'
    innerDiv.innerHTML = message

    // div inherits innerDiv
    div.appendChild(innerDiv)

    // apply to the body
    document.body.appendChild(div)

    // then remove it later
    var remove = function () {
      div.className = 'messageBanner animated fadeOut ' + type
      $timeout(function () {
        div.remove()
      }, 700)
    }

    // if timeout is set to 0, it won't go away on its own
    $timeout(remove, timeout || 3000)

    div.addEventListener('click', remove, false)
  }
})
