app.directive('responseTimePlot', function () {
  return {
    scope: {
      values: '=',
      counts: '='
    },
    replace: true,
    templateUrl: 'responseTimePlot.html',
    link: function ($scope, element, attrs) {
      // combine the pairs into an easier, readable format
      var data = []
      for (var i = 0; i < $scope.values.length; i++) {
        data.push({
          count: $scope.counts[i],
          value: $scope.values[i]
        })
      }

      data = _.chain(data)
        .filter(function (d) {
          return d.count && d.value > 0
        })
        .value()

      var values = []
      var arr = []
      if (data.length >= 24) {
        _.each(data, function (d) {
          arr.push(d.value)
          if (arr.length > 12) {
            arr.shift()
            values.push(Math.round(_.sum(arr) / 12))
          }
        })
      } else {
        values = _.map(data, 'value')
      }
      if (values.length === 1) {
        values.push(values[0])
      }

      $scope.x = _.range(0, values.length)
      $scope.y = values

      $scope.min = _.chain($scope.values)
        .filter(function(v) {
          return v > 0
        })
        .min()
        .value()
      $scope.max = _.max($scope.values)
    }
  }
})
