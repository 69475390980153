app.controller('teamNotifications', function ($scope, $location, authService, $http, appConfig) {
  $scope.currentUser = authService.currentUser()
  authService.updateUser()


  $scope.startSlackIntegration = function () {
    $location.path('/slack')
  }

  $scope.removeSlackIntegration = function (slackId) {
    $http.delete(appConfig.API_URL + '/api/v1/slacks/' + slackId)
      .then(function () {
        authService.updateUser()
      })
  }
})
