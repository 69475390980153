app.directive('alertsDropdown', function (authService, $http, appConfig) {
  return {
    scope: {},
    replace: true,
    templateUrl: 'alertsDropdown.html',
    link: function ($scope, element) {
      $scope.currentUser = authService.currentUser()

      var getNotifications = function () {
        $http.get(appConfig.API_URL + '/api/v1/alerts', {
          params: {
            filter: 'active'
          }
        })
        .then(function (resp) {
          $scope.alerts = resp.data.data
        })
      }

      getNotifications()
    }
  }
})
