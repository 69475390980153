app.directive('errorPlot', function () {
  return {
    scope: {
      timestamps: '=',
      errorCount: '=',
      totalCount: '='
    },
    replace: true,
    templateUrl: 'errorPlot.html',
    link: function ($scope, element, attrs) {
      var points = 12
      var size = $scope.totalCount.length / points
      var numOfRows = 2
      var rowSize = size / numOfRows
      var values = []
      for (var i = 0; i < points; i++) {
        var totalErrorCount = _.sum($scope.errorCount.slice(i * size, (i+1) * size))
        var totalCount = _.sum($scope.totalCount.slice(i * size, (i+1) * size))

        var errorRate = 0
        if (totalCount > 0) {
          errorRate = totalErrorCount / totalCount
        }

        values.push({
          pct: errorRate,
          count: totalErrorCount
        })
      }


      // find the max value
      // for the calculated error percentages above
      var maxRate = 0
      for (var i = 0; i < values.length; i++) {
        if (maxRate < values[i].pct) {
          maxRate = values[i].pct
        }
      }

      $scope.normalizeValue = function (val) {
        if (maxRate === 0) {
          return 0
        }

        return val / maxRate
      }

      // split into two columns, this makes it into
      // a 3d array
      $scope.blockRows = []
      var row = []
      for (var i = 0; i < values.length; i++) {
        row.push(values[i])
        if (i % (points / numOfRows) === (points / numOfRows) - 1) {
          $scope.blockRows.push(row)
          row = []
        }
      }

      // this gives the pct that is displayed at the top
      var total = _.sum($scope.totalCount)
      var totalError = _.sum($scope.errorCount)
      $scope.pct = totalError / total

      $scope.getTimestamp = function (row, column) {
        return $scope.timestamps[(row * rowSize * points) + (column * points)]
      }

      $scope.getRate = function (rate) {
        return Math.round(rate.pct * 100)
      }
    }
  }
})
