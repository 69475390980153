app.directive('alertRow', function (authService, $http, appConfig) {
  return {
    scope: {
      alert: '='
    },
    replace: true,
    templateUrl: 'alertRow.html',
    link: function ($scope, element) {
      $scope.description = $scope.alert.description.replace(/\*(.*)\*/ig, '<strong>$1</strong>')
    }
  }
})
