app.service('authService', function ($http, $window, $document, $cookies, $location, appConfig, messageBanner, $route, $window) {
  // functions that help us with authentication
  // and our current application state based on if
  // the user has logged in or not
  var currentUser = {}

  // helps keep cookies in place
  var makeExpiryDate = function () {
    var now = new Date()
    return new Date(now.getFullYear(), now.getMonth()+1, now.getDate())
  }
  $document.cookie = 'visited=yes; expires=' + makeExpiryDate().toGMTString()

  // reset everything
  var reset = this.reset = function () {
    $http.defaults.headers.common['Authorization'] = undefined

    $window.sessionStorage.removeItem('User')
    $window.sessionStorage.removeItem('ShadowAuthToken')
    $cookies.remove('AuthToken', {
      path: '/'
    })

    currentUser = {}

    $window.Intercom("shutdown")
  }

  // this helps with user sessions, primarily for logins
  var setCurrentUser = function (user) {
    _.assign(currentUser, user)

    $window.sessionStorage.setItem('User', JSON.stringify(user))

    if (!isShadowing()) {
      $window.Intercom("boot", {
        app_id: appConfig.INTERCOM_APP_ID,
        name: user.full_name,
        email: user.email,
        user_id: user.id,
        user_hash: user.user_hash,
        company: {
          id: user.team.id,
          name: user.team.name
        }
      })
    }
  }

  var refreshSession = function () {
    if ($http.defaults.headers.common['Authorization']) {
      $http.get(appConfig.API_URL + '/api/v1/session')
        .then(function (response) {
          setCurrentUser(response.data.data)
        }, function (response) {
          if (response.status === -1) {
            return
          }

          reset()
        })
    }
  }

  // will restore the user data from localStorage then
  // refresh and update from the API
  var getCurrentUser = function () {
    try {
      if ($window.sessionStorage.getItem('User')) {
        _.assign(currentUser, JSON.parse($window.sessionStorage.getItem('User')))
      } else if ($cookies.get('User')) {
        _.assign(currentUser, JSON.parse($cookies.get('User')))
      }
    } catch (e) { }

    refreshSession()
  }

  // store authtoken usually after login or registration
  var setAuthToken = function (authToken) {
    $http.defaults.headers.common['Authorization'] = authToken
  }

  // initialize the authService
  this.init = function () {
    // user stage setup, see if we're logged in
    if ($cookies.get('AuthToken')) {
      if ($window.sessionStorage.getItem('ShadowAuthToken')) {
        setAuthToken($window.sessionStorage.getItem('ShadowAuthToken'))
      } else {
        setAuthToken($cookies.get('AuthToken'))
      }

      getCurrentUser()
    } else {
      currentUser = {}
    }
  }

  // check for authentication method
  this.isAuthenticated = function () {
    if (currentUser.id) {
      return true
    }

    return false
  }

  this.isSuperAdmin = function () {
    if (currentUser && currentUser.id && currentUser.is_super_admin) {
      return true
    }

    return false
  }

  // helps us refresh session variables
  this.updateUser = function (data) {
    if (!data) {
      refreshSession()
      return
    }

    _.assign(currentUser, data)
    setCurrentUser(currentUser)
  }

  this.currentUser = function () {
    if (currentUser.id) {
      return currentUser
    }

    return null
  }

  var isShadowing = this.isShadowing = function () {
    if ($window.sessionStorage.getItem('ShadowAuthToken')) {
      return true
    }

    return false
  }

  var setShadow = function (data) {
    $window.Intercom("shutdown")

    // set the current token
    $window.sessionStorage.setItem('ShadowAuthToken', data.token)
    setAuthToken(data.token)

    // set all the appropriate data to shdow as a user
    setCurrentUser(data)

    // reload the current route
    $route.reload()
  }

  this.shadow = function (userId) {
    return $http.get(appConfig.API_URL + '/api/v1/admin/users/' + userId + '/impersonate')
      .then(function (response) {
        setShadow(response.data.data)
      })
  }

  this.unshadow = function () {
    // update the current tokens we need
    $window.sessionStorage.removeItem('ShadowAuthToken')
    setAuthToken($cookies.get('AuthToken'))

    // obtain the current user
    getCurrentUser()

    // redirect to the proper path
    $location.path('/admin')
  }

  var handleAuthentication = this.handleAuthentication = function (response) {
    // going inside the return data object
    $cookies.put('User', response.data.data, {
      expires: makeExpiryDate(),
      path: '/'
    })
    $cookies.put('AuthToken', response.data.data.token, {
      expires: makeExpiryDate(),
      path: '/'
    })
    setAuthToken(response.data.data.token)
    setCurrentUser(response.data.data)

    return response.data.data
  }

  // this is the login endpoint
  this.login = function (email, password) {
    return $http.post(appConfig.API_URL + '/auth/v1/login', {
      email: email,
      password: password
    })
    .then(handleAuthentication, function (response) {
      if (_.includes(response.data.errors, "invalid")) {
        messageBanner.display('error', 'The email and/or password doesn\'t seem to be right.')
        return
      }

      messageBanner.display('error', 'An error occurred when logging in. ' + response.data.errors.join(', '))
    })
  }

  // register
  this.register = function (teamName, fullName, email, password, inviteToken) {
    var url = appConfig.API_URL + '/auth/v1/signup'
    var data = {
      email: email,
      password: password,
      full_name: fullName
    }

    if (inviteToken) {
      url = appConfig.API_URL + '/auth/v1/confirm_invite'
      data = {
        token: inviteToken,
        user: data
      }
    } else {
      data.team = {
        name: teamName
      }
    }

    return $http.post(url, data)
      .then(handleAuthentication, function (response) {
        if (response.data && response.data.errors) {
          if (_.includes(response.data.errors, "lower_unique_email")) {
            messageBanner.display('error', 'That email address seems to have already been used')
            return
          }

          if (_.includes(response.data.errors, "password")) {
            messageBanner.display('error', 'Your password needs to be at least 6 characters long')
            return
          }

          messageBanner.display('error', 'An error occurred. ' + response.data.errors.join(', '))
          return
        }

        messageBanner.display('error', 'An unexpected error has occured.')
      })
  }

  // this helps us go to the next path
  // that after we login
  this.goNext = function (next) {
    if (!next) {
      $location.path('/')
      return
    }

    var split = next.split('?')

    $location.path(split[0])
    if (split.length > 1) {
      $location.search(split[1])
    } else {
      $location.search("")
    }
  }

  // this helps us maintain the current page
  // but request to login
  this.redirectToLogin = function () {
    $location.path('/login')
    $location.search('next', window.location.pathname + window.location.search)
  }

  // log all page views
  this.logPageView = function () {
    $window.Intercom('update')
  }

  this.logEvent = function (evt, obj) {
    $window.Intercom('trackEvent', evt, obj)
  }
})
