app.controller('healthCheckModal', function ($scope, appConfig, $http) {
  var url = appConfig.API_URL + '/api/v1/summary'
  url = url + '/' + $scope.ngDialogData.baseUrlHash

  $http.get(url, {
    params: {
      duration: 3600
    }
  })
  .then(function (resp) {
    $scope.endpoints = _.chain(resp.data.data)
      .each(function (s) {
        var a = document.createElement('a')
        a.href = s.normalized_url
        s.normalized_uri = a.pathname
      })
      .filter(function (s) {
        if (s.request_method !== 'GET') {
          return false
        }

        if (s.normalized_uri.indexOf(":") >= 0 || s.normalized_uri.indexOf("*") >= 0) {
          return false
        }

        return true
      })
      .value()
  })

  $scope.submit = function () {
    $scope.closeThisDialog($scope.selectedEndpoint)
  }
})
