app.directive('responseTimeScale', function () {
  return {
    scope: {
      values: '=',
      counts: '='
    },
    replace: true,
    templateUrl: 'responseTimeScale.html',
    link: function ($scope, element, attrs) {
      // combine the pairs into an easier, readable format
      var data = []
      for (var i = 0; i < $scope.values.length; i++) {
        data.push({
          count: $scope.counts[i],
          value: $scope.values[i]
        })
      }
      data = _.chain(data)
        .filter(function (d) {
          return d.count && d.value > 0
        })
        .value()

      // get the average value
      var total = 0
      var count = 0
      _.each(_.takeRight(data, 6), function (d) {
        total += d.count * d.value
        count += d.count
      })
      $scope.avg = total / count

      var values = _.map(data, 'value')
      $scope.min = _.min(values)
      $scope.max = _.max(values)

      // calculate percentage on the chart
      $scope.pct = 50
      if ($scope.max - $scope.min > 0) {
        $scope.pct = (($scope.avg - $scope.min) / ($scope.max - $scope.min) * 100)
      }
    }
  }
})
