app.directive('requestCountPlot', function () {
  return {
    scope: {
      values: '='
    },
    replace: true,
    templateUrl: 'requestCountPlot.html',
    link: function ($scope, element, attrs) {
      var points = 24
      var size = $scope.values.length / points
      var values = []
      for (var i = 0; i < points; i++) {
        var slice = $scope.values.slice(i * size, (i+1) * size)
        values.push(_.sum(slice))
      }

      $scope.x = _.range(0, values.length)
      $scope.y = values
    }
  }
})
