app.controller('shadowTeam', function ($scope, appConfig, $http, $routeParams, authService, $location, messageBanner) {
  if (!authService.isAuthenticated()) {
    authService.redirectToLogin()
    return
  }

  if (!authService.isSuperAdmin()) {
    $location.path('/')
    return
  }

  var teamId = $routeParams.team_id

  $http.get(appConfig.API_URL + '/api/v1/admin/teams/' + teamId + '/users')
    .then(function (resp) {
      var users = resp.data.data
      authService.shadow(_.find(users, {'is_admin': true}).id)
    }, function (resp) {
      messageBanner.display('error', 'Failed to shadow team')
      $location.path('/admin/teams')
    })
})
