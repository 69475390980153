app.service('stylesheetService', function () {
  var styles = {}

  var getStylesForClass = function (className) {
    className = '.' + className

    for (var i = 0; i < document.styleSheets.length; i++) {
      var classes = document.styleSheets[i].rules || document.styleSheets[i].cssRules

      if (!classes) {
        continue
      }

      for (var x = 0; x < classes.length; x++) {
         if (classes[x].selectorText == className) {
           if (classes[x].cssText) {
             return classes[x].cssText
           } else {
             return classes[x].style.cssText
           }
         }
      }
    }
  }

  this.getStylesForClass = function (className) {
    if (styles[className]) {
      return styles[className]
    }

    var classStyle = getStylesForClass(className)
    if (styles) {
      styles[className] = classStyle
    }

    return classStyle
  }
})
